import { Ctrl } from ":mods";
import { FormSection } from "./form";
import { HeaderSection } from "./header";

export function ContactPage({ ...props }: Ctrl.Routes.model.MicrositeViewProps) {
  return (
    <main class="w-screen h-full">
      <HeaderSection />
      <FormSection {...props} />
    </main>
  );
}
